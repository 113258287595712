import React from "react";
import spinner from "../media/images/spinner.svg";
export default function StepsContainer(props) {
	return (
		<div
			className={`presale_page-container presale_page-container_input step_container ${
				props.stepsContainerState ? "show" : ""
			}`}
		>
			<span>
				<b>Creation steps</b>
			</span>
			<div>
				<span className={`${props.firstStep ? "checked" : ""}`}>
					<b>1. </b>Create launchpad contract <img src={spinner} alt="" />
				</span>
			</div>
			<div>
				<span className={`${props.secondStep ? "checked" : ""}`}>
					<b>2. </b>Approve contract token spend{" "}
					<img
						style={{ display: `${props.firstStep ? "block" : "none"}` }}
						src={spinner}
						alt=""
					/>
				</span>
			</div>
			<div>
				<span className={`${props.thirdStep ? "checked" : ""}`}>
					<b>3. </b>Deposit funds to created contract
					<img
						style={{ display: `${props.secondStep ? "block" : "none"}` }}
						src={spinner}
						alt=""
					/>
				</span>
			</div>
		</div>
	);
}
