import React from "react";

export default function Footer() {
	return (
		<footer>
			<div className="footer_content">
				<div className="safepad_copyright">
					<span>© 2023 SafePad</span>
				</div>
				<div className="footer_external_links">
					<div className="link">Safemoon</div>
					<div className="link">Coingecko</div>
					<div className="link">Coinmarketcap</div>
					<div className="link">Twitter</div>
					<div className="link">Telegram</div>
					<div className="link">Discord</div>
				</div>
			</div>
		</footer>
	);
}
