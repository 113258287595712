import React, { useContext, useEffect, useState } from "react";
import { WalletProvider } from "../context/WalletProvider";
import LockerDashboardAssets from "../components/LockerDashboardAssets";

const lockerContractAddress = "0xcB4950741Ea9d228410a74F008bF6b35AEa85b2D";
const lockerABI = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "uint256",
				name: "vestingId",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "beneficiary",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "TokenVestingAdded",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "uint256",
				name: "vestingId",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "beneficiary",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "TokenVestingReleased",
		type: "event",
	},
	{
		inputs: [
			{ internalType: "contract IERC20", name: "_token", type: "address" },
			{ internalType: "address", name: "_beneficiary", type: "address" },
			{ internalType: "uint256", name: "_releaseTime", type: "uint256" },
			{ internalType: "uint256", name: "_amount", type: "uint256" },
		],
		name: "addVesting",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "beneficiary",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "uint256[]", name: "_vestingIds", type: "uint256[]" },
		],
		name: "getVestingList",
		outputs: [
			{
				components: [
					{ internalType: "uint256", name: "vestingId", type: "uint256" },
					{ internalType: "contract IERC20", name: "token", type: "address" },
					{ internalType: "uint256", name: "releaseTime", type: "uint256" },
					{ internalType: "uint256", name: "amount", type: "uint256" },
					{ internalType: "address", name: "beneficiary", type: "address" },
					{ internalType: "bool", name: "released", type: "bool" },
				],
				internalType: "struct Vesting.VestingDataFE[]",
				name: "",
				type: "tuple[]",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "", type: "address" },
			{ internalType: "uint256", name: "", type: "uint256" },
		],
		name: "ownerVestings",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "release",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "releaseTime",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "vestingAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		name: "vestings",
		outputs: [
			{ internalType: "contract IERC20", name: "token", type: "address" },
			{ internalType: "uint256", name: "releaseTime", type: "uint256" },
			{ internalType: "uint256", name: "amount", type: "uint256" },
			{ internalType: "address", name: "beneficiary", type: "address" },
			{ internalType: "bool", name: "released", type: "bool" },
		],
		stateMutability: "view",
		type: "function",
	},
];
let idsForSearch = [];
for (let index = 0; index < 1000; index++) {
	idsForSearch.push(index + 1);
}

export default function LockerDashboard() {
	const { ethers, isConnnected, Web3, wallet, errorMsg, succesMsg } =
		useContext(WalletProvider);
	const [showPage, setShowPage] = useState(false);
	const [lockers, setLockers] = useState([]);

	useEffect(() => {
		setTimeout(() => setShowPage(true));
		const getLockers = async () => {
			const web3 = new Web3(window.ethereum);
			const lockerContract = new web3.eth.Contract(
				lockerABI,
				lockerContractAddress
			);

			const lockerResponse = await lockerContract.methods
				.getVestingList(idsForSearch)
				.call();
			if (!lockerResponse) {
				errorMsg("Get lockers error");
			} else {
				let res = [];
				lockerResponse.forEach((lockerInfo) => {
					if (
						lockerInfo[1] !== "0x0000000000000000000000000000000000000000" &&
						!lockerInfo[5]
					) {
						res.push(lockerInfo);
					}
				});
				console.log(res);
				setLockers(res);
			}
		};
		getLockers();
	}, []);
	return (
		<div className={`lockerdashboard_page page ${showPage ? "SHOW" : ""}`}>
			<div className="lockerdashboard_page-header lockerdashboard_page-container">
				<span>NovaLaunch LOCKER DASHBOARD</span>
			</div>
			<LockerDashboardAssets lockers={lockers}></LockerDashboardAssets>
		</div>
	);
}
