import React, { useEffect, useState } from "react";
import logo from "../media/images/logo-no-text.png";
import { Link } from "react-router-dom";

export default function Home() {
	const [showPage, setShowPage] = useState(false);

	useEffect(() => {
		setTimeout(() => setShowPage(true));
	}, []);
	return (
		<div className={`home_page page ${showPage ? "SHOW" : ""}`}>
			<div className="home_page__h">NovaLaunch</div>
			<div className="home_page__info">
				<div className="home_page-logo_container">
					<img src={logo} width="50"></img>
					<p>*NovaLaunch Doesn’t charge any token fee* </p>
				</div>
				<div className="home_page__info-cell">
					<span>Fee from ETH:</span>
					<p>1.75%</p>
				</div>
			</div>
			<div className="home_page__content_container">
				<div className="home_page__content_side">
					<div className="home_page__content__side_header">
						<span>
							<h2>NovaLaunch</h2> Helps <b>everyone</b> to create their own token
							sales or lock up liquidity in few seconds.
						</span>
					</div>
					<div className="home_page__content__side_buttons">
						<Link to={"/presale"} className="default-btn">
							Create New Projects
						</Link>

						<Link to={"/locker"} className="default-btn">
							Safety Lock Tokens
						</Link>
					</div>
					<div
						style={{ marginTop: "1rem" }}
						className="home_page__content__side_buttons"
					>
						<Link to={"/presale/dashboard"} className="default-btn">
							Presales Dashboard
						</Link>

						<Link to={"/locker/dashboard"} className="default-btn">
							Locker Dashboard
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
