import "./App.css";
import { Routes, Route } from "react-router-dom";
import { create, CID } from "ipfs-http-client";
import * as ethers from "ethers";
import Presale from "./pages/Presale";
import Header from "./components/Header";
import Home from "./pages/Home";
import Presales from "./pages/PresalesDashboard";
import Locker from "./pages/Locker";
import ContributePopup from "./components/ContributePopup";
import { WalletProvider } from "./context/WalletProvider";
import LockerDashboard from "./pages/LockerDashboard";
import Footer from "./components/Footer";
import { IpfsProvider } from "./context/IpfsProvider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Web3 from "web3";
const showSuccessToastMessage = (text) => {
	toast.success(text, {
		position: toast.POSITION.BOTTOM_CENTER,
	});
};
const showSuccessToastMessageWithLink = (text, link) => {
	toast.success(text, {
		position: toast.POSITION.BOTTOM_CENTER,
		onClick: window.open(link, "_blank"),
	});
};
const showErrorToastMessage = (text) => {
	toast.error(text, {
		position: toast.POSITION.BOTTOM_CENTER,
	});
};
const launchPadAbi = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "previousAdmin",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "newAdmin",
				type: "address",
			},
		],
		name: "AdminChanged",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "beacon",
				type: "address",
			},
		],
		name: "BeaconUpgraded",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: false, internalType: "uint8", name: "version", type: "uint8" },
		],
		name: "Initialized",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "launchpadAddress",
				type: "address",
			},
		],
		name: "LaunchpadCreated",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "implementation",
				type: "address",
			},
		],
		name: "Upgraded",
		type: "event",
	},
	{
		inputs: [
			{ internalType: "string", name: "_baseURI", type: "string" },
			{ internalType: "address", name: "_token", type: "address" },
			{ internalType: "uint256", name: "_tokenDecimals", type: "uint256" },
			{ internalType: "uint256", name: "_minSaleTotal", type: "uint256" },
			{ internalType: "uint256", name: "_minSaleEnter", type: "uint256" },
			{ internalType: "uint256", name: "_maxSaleEnter", type: "uint256" },
			{ internalType: "uint256", name: "_saleLength", type: "uint256" },
			{ internalType: "uint256", name: "_ethPerToken", type: "uint256" },
		],
		name: "createLaunchpad",
		outputs: [
			{ internalType: "address", name: "launchpadAddress", type: "address" },
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "getAdminAddress",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getLaunchpadAmount",
		outputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "initialize",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		name: "listOfLaunchpads",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "proxiableUUID",
		outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "feeAddress", type: "address" }],
		name: "setAdminAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "newImplementation", type: "address" },
		],
		name: "upgradeTo",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "newImplementation", type: "address" },
			{ internalType: "bytes", name: "data", type: "bytes" },
		],
		name: "upgradeToAndCall",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
];
const contractAddress = "0xf4d6EA695E99805F26cb492dA9C07a40107FC803";
const infuriaApi =
	"Basic MlNoQ2FFakh3b0l1RXAxaHo2MFgxYUF6NTg5OmYxOTFjZDVkYTcxMDVjY2JhOTg3YzJiYTdmMzExYWQ3";

const client = create({
	host: "ipfs.infura.io",
	port: 5001,
	protocol: "https",
	apiPath: "/api/v0",
	headers: {
		authorization: infuriaApi,
	},
});

function App() {
	const [connnected, setConnected] = useState(false);
	const [chainId, setChainID] = useState(null);
	const [balance, setBalance] = useState(null);
	const [wallet, setWallet] = useState(null);
	const [contributePopupState, setContributePopupState] = useState(false);
	const [launchpadAddressToContribute, setLaunchpadAddressToContribute] =
		useState("");

	const contribute = (launchpadAddressToContribute) => {
		if (connnected) {
			setLaunchpadAddressToContribute(launchpadAddressToContribute);
			setContributePopupState(true);
		} else {
			connect();
			showErrorToastMessage("Connect wallet first");
		}
	};
	function handleAccountsChanged(accounts) {
		if (accounts.length === 0) {
			// MetaMask is locked or the user has not connected any accounts.
			showErrorToastMessage("Please connect to MetaMask.");
		} else if (accounts[0] !== wallet) {
			// Reload interface with accounts[0].
			setWallet(accounts[0]);
			showSuccessToastMessage("Connected");
			setConnected(true);
			getAssets(accounts[0]);
		}
	}
	function handleChainChanged(chain) {
		setChainID(chain);
	}

	function checkConnection() {
		window.ethereum
			.request({ method: "eth_accounts" })
			.then(handleAccountsChanged)
			.catch((error) => {
				showErrorToastMessage(error.message);
			});
	}
	function getAssets(wallet) {
		const getAccountBalance = (wallet) => {
			window.ethereum
				.request({ method: "eth_getBalance", params: [wallet, "latest"] })
				.then((balance) => {
					setBalance(balance);
				})
				.catch((error) => {
					showErrorToastMessage(error.message);
				});
		};
		const getChainId = () => {
			window.ethereum
				.request({ method: "eth_chainId" })
				.then((chain) => setChainID(chain))
				.catch((error) => showErrorToastMessage(error.message));
		};
		getAccountBalance(wallet);
		getChainId();
	}

	function connect() {
		if (window.ethereum) {
			window.ethereum
				.request({ method: "eth_requestAccounts" })
				.then(handleAccountsChanged)
				.catch((error) => {
					if (error.code === 4001) {
						// EIP-1193 userRejectedRequest error
						console.log("Please connect to MetaMask.");
					} else {
						showErrorToastMessage(error.message);
					}
				});
		}
	}
	function disconnect() {
		setWallet(null);
		setBalance(null);
		setChainID(null);
		setConnected(false);
	}
	useEffect(() => {
		if (window.ethereum) {
			checkConnection();
			window.ethereum.on("accountsChanged", async function (accounts) {
				if (connnected) {
					setWallet(accounts[0]);
				}
			});
			window.ethereum.on("chainChanged", handleChainChanged);
		}
	}, []);

	useEffect(() => {
		if (window.ethereum) {
			if (chainId !== "0x1") {
				toast.warning(
					"Please, switch chain to ethereum mainnet! This is necessary for the correct operation of the application",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);

				setTimeout(() => {
					window.ethereum
						.request({
							method: "wallet_switchEthereumChain",
							params: [{ chainId: "0x1" }], // chainId must be in hexadecimal numbers
						})
						.catch((error) => console.log(error));
				}, 2000);
			}
		}
	}, [chainId]);
	
	return (
		<WalletProvider.Provider
			value={{
				launchPadAbi: launchPadAbi,
				contractAddress: contractAddress,
				ethers: ethers,
				Web3: Web3,
				wallet: wallet,
				balance: balance,
				chainId: chainId,
				connectWallet: connect,
				disconnectWallet: disconnect,
				isConnnected: connnected,
				errorMsg: showErrorToastMessage,
				succesMsg: showSuccessToastMessage,
				succesMsgWithLink: showSuccessToastMessageWithLink,
				contribute: contribute,
			}}
		>
			<IpfsProvider.Provider
				value={{ api: infuriaApi, client: client, CID: CID }}
			>
				<div className="app_content">
					<Header></Header>
					<Routes>
						<Route path="/" index element={<Home />} />
						<Route path="/presale" element={<Presale />} />
						<Route
							path="/presale/dashboard"
							element={
								<Presales
									error={showErrorToastMessage}
									succes={showSuccessToastMessage}
								/>
							}
						/>
						<Route path="/locker" element={<Locker />} />
						<Route path="/locker/dashboard" element={<LockerDashboard />} />
					</Routes>
					<Footer></Footer>
					<ContributePopup
						contributePopupState={contributePopupState}
						setContributePopupState={setContributePopupState}
						launchpadAddressToContribute={launchpadAddressToContribute}
					></ContributePopup>
					<ToastContainer></ToastContainer>
				</div>
			</IpfsProvider.Provider>
		</WalletProvider.Provider>
	);
}

export default App;
