import React from "react";
import logo from "../media/images/logo-no-text.png";
import ConnectButton from "./ConnectButton";
import { Link } from "react-router-dom";
export default function Header() {
	return (
		<header>
			<Link to={"/"}>
				<div className="header-logo_container">
					<img src={logo} width="50"></img>
					<p>NOVACORE</p>
				</div>
			</Link>
			<div className="header-connect-container">
				<ConnectButton></ConnectButton>
			</div>
		</header>
	);
}
