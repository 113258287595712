import React, { useContext, useEffect, useState } from "react";
import "../Track.css";
import { WalletProvider } from "../context/WalletProvider";

const lockerABI = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "uint256",
				name: "vestingId",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "beneficiary",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "TokenVestingAdded",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "uint256",
				name: "vestingId",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "beneficiary",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "TokenVestingReleased",
		type: "event",
	},
	{
		inputs: [
			{ internalType: "contract IERC20", name: "_token", type: "address" },
			{ internalType: "address", name: "_beneficiary", type: "address" },
			{ internalType: "uint256", name: "_releaseTime", type: "uint256" },
			{ internalType: "uint256", name: "_amount", type: "uint256" },
		],
		name: "addVesting",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "beneficiary",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "uint256[]", name: "_vestingIds", type: "uint256[]" },
		],
		name: "getVestingList",
		outputs: [
			{
				components: [
					{ internalType: "uint256", name: "vestingId", type: "uint256" },
					{ internalType: "contract IERC20", name: "token", type: "address" },
					{ internalType: "uint256", name: "releaseTime", type: "uint256" },
					{ internalType: "uint256", name: "amount", type: "uint256" },
					{ internalType: "address", name: "beneficiary", type: "address" },
					{ internalType: "bool", name: "released", type: "bool" },
				],
				internalType: "struct Vesting.VestingDataFE[]",
				name: "",
				type: "tuple[]",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "", type: "address" },
			{ internalType: "uint256", name: "", type: "uint256" },
		],
		name: "ownerVestings",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "release",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "releaseTime",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
		name: "vestingAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		name: "vestings",
		outputs: [
			{ internalType: "contract IERC20", name: "token", type: "address" },
			{ internalType: "uint256", name: "releaseTime", type: "uint256" },
			{ internalType: "uint256", name: "amount", type: "uint256" },
			{ internalType: "address", name: "beneficiary", type: "address" },
			{ internalType: "bool", name: "released", type: "bool" },
		],
		stateMutability: "view",
		type: "function",
	},
];
const approveErc20ABI = [
	{
		constant: false,
		inputs: [
			{
				name: "spender",
				type: "address",
			},
			{
				name: "amount",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [
			{
				name: "",
				type: "uint8",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
];
const balanceOfABI = [
	{
		inputs: [{ internalType: "address", name: "account", type: "address" }],
		name: "balanceOf",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
];

function calculateSecondsUntilFutureDate(futureDate) {
	const now = new Date();
	const futureTime = new Date(futureDate);

	// Вычисляем разницу в миллисекундах между будущей датой и текущим временем
	const timeDifferenceInMillis = futureTime.getTime() - now.getTime();

	// Преобразуем разницу в миллисекундах в секунды
	const secondsDifference = Math.floor(timeDifferenceInMillis / 1000);

	return secondsDifference;
}
const lockerContractAddress = "0xcB4950741Ea9d228410a74F008bF6b35AEa85b2D";

export default function Locker() {
	const { ethers, isConnnected, Web3, wallet, errorMsg, succesMsg } =
		useContext(WalletProvider);
	const [showPage, setShowPage] = useState(false);
	const [range, setRange] = useState(0);
	const [eligible, setEligible] = useState(false);
	const [address, setAddress] = useState("");
	const [unlock, setUnlock] = useState("");

	const [balance, setBalance] = useState(0);

	const lockFunds = async () => {
		if (eligible) {
			const web3 = new Web3(window.ethereum);
			const userContract = new web3.eth.Contract(approveErc20ABI, address);
			let decimals = parseInt(await userContract.methods.decimals().call());
			let value = range;

			console.log("approve ", value.toString());
			const approveResponse = await userContract.methods
				.approve(lockerContractAddress, value.toString())
				.send({ from: wallet, gasPrice: "1000000000" })
				.then((res) => res)
				.catch((error) => errorMsg(error.message));

			if (!approveResponse) {
				errorMsg("Token spend approve error");
			} else {
				succesMsg(approveResponse.transactionHash);
				const lockerContract = new web3.eth.Contract(
					lockerABI,
					lockerContractAddress
				);
				let unlockTimeInSeconds = calculateSecondsUntilFutureDate(
					new Date(unlock)
				);
				if (unlockTimeInSeconds <= 0) {
					errorMsg("Data error");
				} else {
					const lockResponse = await lockerContract.methods
						.addVesting(
							address,
							wallet,
							unlockTimeInSeconds.toString(),
							value.toString()
						)
						.send({ from: wallet, gasPrice: "1000000000" })
						.catch((error) => errorMsg(error.message));
					if (!lockResponse) {
						errorMsg("Lock error");
					} else {
						succesMsg("Done!");
						succesMsg(lockResponse.transactionHash);
					}
				}
			}
		}
	};

	const onRangeChange = (e) => {
		e.preventDefault();
		setRange(parseInt(e.target.value));
	};
	const onAddressChange = (e) => {
		setAddress(e.target.value);
	};
	const onTimeChange = (e) => {
		setUnlock(e.target.value);
	};
	useEffect(() => {
		setTimeout(() => setShowPage(true));
	}, []);

	useEffect(() => {
		if (balance === 0) setRange(0);
	}, [balance]);
	useEffect(() => {
		const getBalance = async () => {
			console.log(address);

			if (isConnnected) {
				console.log("connected");

				if (ethers.isAddress(address)) {
					const web3 = new Web3(window.ethereum);
					console.log(await web3.eth.getCode(address));

					if ((await web3.eth.getCode(address)) !== "0x") {
						console.log("Address is contract");
						const contract = new web3.eth.Contract(balanceOfABI, address);
						let balanceOf = await contract.methods.balanceOf(wallet).call();
						setBalance(parseInt(balanceOf));
					} else setBalance(0);
				} else setBalance(0);
			} else setBalance(0);
		};
		getBalance();
	}, [address, isConnnected]);
	useEffect(() => {
		if (balance !== 0 && range !== 0 && address && unlock && isConnnected) {
			setEligible(true);
		} else setEligible(false);
	}, [balance, range, address, unlock]);

	return (
		<div className={`locker_page page ${showPage ? "SHOW" : ""}`}>
			<div className="locker_page-header locker_page-container">
				<span>NovaLaunch LOCKER</span>
			</div>
			<div className="locker_page-form locker_page-container">
				<div className="token_address">
					<span>Token Address:</span>
					<input type="text" value={address} onChange={onAddressChange} />
				</div>
				<div className="unlock_time">
					<span>UnLock time:</span>{" "}
					<input type="datetime-local" value={unlock} onChange={onTimeChange} />
				</div>

				<div className="lock_amount">
					<span>Select % of your Tokens to Lock:</span>
					<input
						onChange={onRangeChange}
						value={range}
						disabled={balance === 0}
						max={balance}
						min={0}
						step={balance > 455465354519 ? 10 : 1}
						className="win10-thumb"
						type="range"
					/>
					<div className="amount_buttons">
						<button onClick={() => setRange(balance * 0.25)}>25%</button>
						<button onClick={() => setRange(balance * 0.5)}>50%</button>
						<button onClick={() => setRange(balance * 0.75)}>75%</button>
						<button onClick={() => setRange(balance * 1)}>100%</button>
					</div>
					<p>
						Balance: <b>{balance}</b>
					</p>
					<p>
						Your tokens to be locked <b>{range}</b>
					</p>
				</div>
				<div className="confirm">
					<button disabled={!eligible} onClick={lockFunds}>
						Lock
					</button>
				</div>
			</div>
		</div>
	);
}
