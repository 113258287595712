import React, { useContext, useEffect, useState } from "react";
import { WalletProvider } from "../context/WalletProvider";

export default function ContributePopup(props) {
	const { balance, ethers, Web3, wallet, succesMsg, errorMsg } =
		useContext(WalletProvider);
	const [contributeAmount, setContributeAmount] = useState(null);

	const participateABI = [
		{
			inputs: [],
			name: "participate",
			outputs: [],
			stateMutability: "payable",
			type: "function",
		},
	];

	const onContributeAmountChange = (e) => {
		setContributeAmount(parseFloat(e.target.value));
	};
	useEffect(() => {
		setContributeAmount(null);
	}, [props.contributePopupState]);

	const participate = () => {
		const web3 = new Web3(window.ethereum);
		const contract = new web3.eth.Contract(
			participateABI,
			props.launchpadAddressToContribute
		); //
		let value = web3.utils.toWei(contributeAmount, "ether");

		console.log(value);
		contract.methods
			.participate()
			.send({ from: wallet, gasPrice: "10000000", value: value })
			.then((res) => succesMsg(res.transactionHash))
			.catch((error) => errorMsg(error.message));
	};

	return (
		<div
			className={`contribute_layer ${props.contributePopupState ? "show" : ""}`}
		>
			<div
				onClick={() => props.setContributePopupState(false)}
				className="contribute_closer"
			></div>
			<div className="contribute_container">
				<div className="contribute_balance">
					<span>ETH balance:</span>
					<button className="balance">
						<span>
							{balance ? Number(ethers.formatEther(balance)).toFixed(2) : 0}
						</span>
					</button>
				</div>
				<div className="contibute_amount">
					<input
						className="contribute_amount-input"
						placeholder="Amount"
						value={contributeAmount}
						onChange={onContributeAmountChange}
						type="number"
					/>
					<button className="max_amount">
						<span
							onClick={() =>
								setContributeAmount(ethers.formatEther(balance) * 0.9)
							}
						>
							MAX
						</span>
					</button>
				</div>
				<div className="contribute_buttons">
					<button
						className="contribute_button-confirm"
						disabled={contributeAmount <= 0 && contributeAmount === ""}
						onClick={() => participate()}
					>
						Contribute
					</button>
					<button
						className="contribute_button-close"
						onClick={() => props.setContributePopupState(false)}
					>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}
