import React, { useContext } from "react";
import { WalletProvider } from "../context/WalletProvider";

function short(wallet) {
  return (
    wallet[0] +
    wallet[1] +
    wallet[3] +
    wallet[4] +
    "..." +
    wallet.charAt(wallet.length - 4) +
    wallet.charAt(wallet.length - 3) +
    wallet.charAt(wallet.length - 2) +
    wallet.charAt(wallet.length - 1)
  );
}

export default function ConnectButton() {
  const { wallet, isConnnected, disconnectWallet, connectWallet } =
    useContext(WalletProvider);

  return (
    <button
      onClick={() => (isConnnected ? disconnectWallet() : connectWallet())}
      className="default-btn"
    >
      {isConnnected ? short(wallet) : "Connect"}
    </button>
  );
}
