import React, { useEffect, useState } from "react";
import notify from "../media/images/notify.svg";
import logo from "../media/images/logo-no-text.png";
import { WalletProvider } from "../context/WalletProvider";
import { IpfsProvider } from "../context/IpfsProvider";
import { useContext } from "react";

import StepsContainer from "../components/StepsContainer";
import "cooltipz-css";

const defaultJson = {
	token: "",
	tokenName: "",
	tokenSlug: "",
	rate: "",
	softcap: null,
	hardcap: null,
	minbuy: null,
	maxbuy: null,
	logo: "",
	website: "",
	github: "",
	twitter: "",
	reddit: "",
	telegram: "",
	description: "",
	starttime: "",
	endtime: "",
};
const approveErc20ABI = [
	{
		constant: false,
		inputs: [
			{
				name: "spender",
				type: "address",
			},
			{
				name: "amount",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [
			{
				name: "",
				type: "uint8",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
];
const launchpadDepositABI = [
	{
		inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
		name: "deposit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];

function calculateSeconds(startTime, endTime) {
	const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
	const seconds = Math.floor(timeDiff / 1000);
	return seconds;
}

export default function Presale() {
	const {
		ethers,
		isConnnected,
		Web3,
		wallet,
		succesMsg,
		errorMsg,
		launchPadAbi,
		contractAddress,
		chainId,
	} = useContext(WalletProvider);
	const { client } = useContext(IpfsProvider);
	const [showPage, setShowPage] = useState(false);
	const [jsonObj, setJsonObj] = useState(defaultJson);
	const [eligible, setEligble] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [stepsContainerState, setStepsContainerState] = useState(false);
	const [firstStep, setfirstStep] = useState(false);
	const [secondStep, setsecondStep] = useState(false);
	const [thirdStep, setthirdStep] = useState(false);

	function dropSteps() {
		setStepsContainerState(false);
		setfirstStep(false);
		setsecondStep(false);
		setthirdStep(false);
	}
	async function depositFunds(web3, launchpadAddress, value) {
		let launchpadContract = new web3.eth.Contract(
			launchpadDepositABI,
			launchpadAddress
		);
		console.log("deposit ", value);

		return await launchpadContract.methods
			.deposit(value.toString())
			.send({ from: wallet, gasPrice: "100000000" })
			.catch((error) => errorMsg(error.message));
	}
	async function approveTokenSpend(web3, launchpadAddress, decimals) {
		let userContract = new web3.eth.Contract(approveErc20ABI, jsonObj.token);

		console.log("decimals ", decimals);
		let value = (jsonObj.hardcap / jsonObj.rate) * 10 ** decimals;

		console.log("approve ", value);
		return [
			await userContract.methods
				.approve(launchpadAddress, value.toString())
				.send({ from: wallet, gasPrice: "1000000000" })
				.then((res) => res)
				.catch((error) => errorMsg(error.message)),
			value,
		];
	}
	async function createLaunchpadAddress(web3, ifpfPath, decimals) {
		const contract = new web3.eth.Contract(launchPadAbi, contractAddress);

		console.log(jsonObj.rate)

		return await contract.methods
			.createLaunchpad(
				ifpfPath,
				jsonObj.token,
				decimals,
				Web3.utils.toWei(jsonObj.softcap, "ether"),
				Web3.utils.toWei(jsonObj.minbuy, "ether"),
				Web3.utils.toWei(jsonObj.maxbuy, "ether"),
				calculateSeconds(
					new Date(jsonObj.starttime),
					new Date(jsonObj.endtime)
				),
				Web3.utils.toWei(jsonObj.rate, "ether")
			)
			.send({ from: wallet, gasPrice: "1000000000" })
			.then((res) => res)
			.catch((error) => errorMsg(error.message));
	}
	async function createLaunchpad() {
		if (isConnnected && eligible) {
			const web3 = new Web3(window.ethereum);
			if (chainId !== "0x1") {
				errorMsg("Change chain to Ethereum Mainnet");
				return 1;
			}

			if ((await web3.eth.getCode(jsonObj.token)) == "0x") {
				errorMsg(jsonObj.token + " Is not contract address");
				setJsonObj((jsonObj) => ({
					...jsonObj,
					token: "",
				}));
				return 1;
			}

			let ifpfPath = (await client.add(JSON.stringify(jsonObj))).path;
			if (!ifpfPath) {
				errorMsg("ipfs error");
				dropSteps();

				return 1;
			}
			let userContract = new web3.eth.Contract(approveErc20ABI, jsonObj.token);
			let decimals = Number(await userContract.methods.decimals().call());
			setStepsContainerState(true);

			let lauchpadCreationResponse = await createLaunchpadAddress(
				web3,
				ifpfPath,
				decimals
			);
			if (!lauchpadCreationResponse) {
				errorMsg("Lauchpad creation error");
				dropSteps();
			} else {
				setfirstStep(true);
				succesMsg(lauchpadCreationResponse.transactionHash);
				let launchpadAddress = lauchpadCreationResponse.logs[0].address;
				console.log(launchpadAddress);
				console.log(ifpfPath);
				const approveResponse = await approveTokenSpend(
					web3,
					launchpadAddress,
					decimals
				);

				if (!approveResponse[0]) {
					errorMsg("Token spend approve error");
					dropSteps();
				} else {
					setsecondStep(true);
					succesMsg(approveResponse.transactionHash);

					const depositResponse = await depositFunds(
						web3,
						launchpadAddress,
						approveResponse[1]
					);

					if (!depositResponse) {
						errorMsg("Deposit funds error");
						dropSteps();
					} else {
						setthirdStep(true);
						succesMsg("Done. Your welcome!");
						console.log(depositResponse);
						succesMsg(depositResponse.transactionHash);
						setTimeout(() => dropSteps(), 5000);
					}
				}
			}
		}
	}

	const changeRate = (e) => {
		if (e.target.value !== "") {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				rate: e.target.value.toString(),
			}));
		} else {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				rate: null,
			}));
		}
	};
	const changeToken = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			token: e.target.value,
		}));
	};
	const changeTokenSlug = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			tokenSlug: e.target.value,
		}));
	};
	const changeTokenName = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			tokenName: e.target.value,
		}));
	};
	const changeSoftCap = (e) => {
		if (e.target.value !== "") {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				softcap: parseFloat(e.target.value),
			}));
		} else {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				softcap: null,
			}));
		}
	};
	const changeHardCap = (e) => {
		if (e.target.value !== "") {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				hardcap: parseFloat(e.target.value),
			}));
		} else {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				hardcap: null,
			}));
		}
	};
	const changeMinBuy = (e) => {
		if (e.target.value !== "") {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				minbuy: parseFloat(e.target.value),
			}));
		} else {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				minbuy: null,
			}));
		}
	};
	const changeMaxBuy = (e) => {
		if (e.target.value !== "") {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				maxbuy: parseFloat(e.target.value),
			}));
		} else {
			setJsonObj((jsonObj) => ({
				...jsonObj,
				maxbuy: null,
			}));
		}
	};
	const changeLogo = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			logo: e.target.value,
		}));
	};
	const changeGit = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			github: e.target.value,
		}));
	};
	const changeTg = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			telegram: e.target.value,
		}));
	};
	const changeReddit = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			reddit: e.target.value,
		}));
	};
	const changeTwitter = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			twitter: e.target.value,
		}));
	};
	const changeWeb = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			website: e.target.value,
		}));
	};
	const changeDescription = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			description: e.target.value,
		}));
	};
	const changeStart = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			starttime: e.target.value,
		}));
	};
	const changeEnd = (e) => {
		setJsonObj((jsonObj) => ({
			...jsonObj,
			endtime: e.target.value,
		}));
	};

	useEffect(() => {
		setTimeout(() => setShowPage(true));
	}, []);
	useEffect(() => {
		let errorMessage = "";
		if (!ethers.isAddress(jsonObj.token)) {
			errorMessage += "Token Address,\n";
		}
		if (jsonObj.tokenName.length <= 1) {
			errorMessage += "Token Name,\n";
		}
		if (jsonObj.tokenSlug.length <= 1) {
			errorMessage += "Token Tiker,\n";
		}
		if (jsonObj.rate == null || jsonObj.rate < 0) {
			errorMessage += "Presale Rate,\n";
		}
		if (jsonObj.softcap == null || jsonObj.softcap < 0) {
			errorMessage += "Softcap,\n";
		}
		if (jsonObj.hardcap == null || jsonObj.hardcap < 0) {
			errorMessage += "Hardcap,\n";
		}
		if (jsonObj.minbuy == null || jsonObj.minbuy < 0) {
			errorMessage += "Min Buy,\n";
		}
		if (jsonObj.maxbuy == null || jsonObj.maxbuy < 0) {
			errorMessage += "Max Buy,\n";
		}
		if (!jsonObj.starttime || !jsonObj.endtime) {
			errorMessage += "Start or End \n";
		} else if (new Date(jsonObj.starttime) > new Date(jsonObj.endtime)) {
			errorMessage += "Start cannot be bigger than end";
		}
		if (errorMessage === "") {
			setEligble(true);
			setErrorMessage("");
		} else {
			setEligble(false);
			setErrorMessage(errorMessage + "is invalid");
		}

		console.log(errorMessage);
	}, [jsonObj]);
	return (
		<div className={`presale_page page ${showPage ? "SHOW" : ""}`}>
			<div className="presale_page-container presale_page-container_information">
				<div className="side">
					<span>CREATE/MANAGE PRESALE</span>
					<p>Get started in just a few simple steps!</p>
				</div>
				<div className="side">
					<div className="description">
						This process is entirely decentralized, we cannot be held reponsible
						for incorrect entry of information or be held liable for anything
						related to your use of our platform. Please ensure you enter all
						your details to the best accuracy possible and that you are in
						compliance with your local laws and regulations. If your local laws
						require KYC and AML please ensure you complete this on your own
						behalf!
					</div>
				</div>
			</div>
			<div className="presale_page-container presale_page-container_warning">
				<img src={notify} alt="" srcset="" />
				<span>
					For tokens with burns, rebase or other special transfers please ensure
					you have a way to whitelist multiple addresses or turn off the special
					transfer events (By setting fees to 0 for example for the duration of
					the presale)
				</span>
			</div>
			<div className="presale_page-container presale_page-container_fetched">
				<img src={logo} alt="" srcset="" width="50" />
				<div className="item">
					<b>*Novacore Doesn't Charge Any Token Fee*</b>
				</div>
				<div className="item">
					ETH Raised: <b>1.75%</b>
				</div>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>1.</b> Token Address
				</span>
				<p>Enter your token address:</p>
				<input
					type="text"
					placeholder="0xb73F5350b14B1dD62E705d8DbB076AB55594947b"
					value={jsonObj.token}
					onChange={changeToken}
				/>
				<p>Enter your token name:</p>

				<input
					type="text"
					placeholder="TOKEN"
					value={jsonObj.tokenName}
					onChange={changeTokenName}
				/>
				<p>Enter your token tiker:</p>

				<input
					type="text"
					placeholder="TKN"
					value={jsonObj.tokenSlug}
					onChange={changeTokenSlug}
				/>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>2.</b> Presale Rate
				</span>
				<p>Price for 1 token in ETH:</p>
				<input
					type="number"
					placeholder="1000"
					value={jsonObj.rate}
					onChange={changeRate}
				/>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>3.</b> Soft Hard Cap
				</span>
				<p>Soft Cap in ETH:</p>
				<input
					type="number"
					placeholder="10000"
					value={jsonObj.softcap}
					onChange={changeSoftCap}
				/>
				<p>Hard Cap in ETH:</p>
				<input
					type="number"
					value={jsonObj.hardcap}
					onChange={changeHardCap}
					placeholder="10000"
				/>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>4.</b> Contribution Limits
				</span>
				<p>Min Buy in ETH:</p>
				<input
					type="number"
					placeholder="0.1"
					value={jsonObj.minbuy}
					onChange={changeMinBuy}
				/>
				<p>Max Buy in ETH:</p>
				<input
					type="number"
					placeholder="3"
					value={jsonObj.maxbuy}
					onChange={changeMaxBuy}
				/>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>5.</b> Additional Information
				</span>
				<p>Logo Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeLogo}
					value={jsonObj.logo}
				/>
				<p>Website Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeWeb}
					value={jsonObj.website}
				/>
				<p>Github Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeGit}
					value={jsonObj.github}
				/>
				<p>Twitter Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeTwitter}
					value={jsonObj.twitter}
				/>
				<p>Reddit Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeReddit}
					value={jsonObj.reddit}
				/>
				<p>Telegram Link</p>
				<input
					type="text"
					placeholder=""
					onChange={changeTg}
					value={jsonObj.telegram}
				/>
				<p>Project Description</p>
				<input
					type="text"
					placeholder=""
					onChange={changeDescription}
					value={jsonObj.description}
				/>
			</div>
			<div className="presale_page-container presale_page-container_input">
				<span>
					<b>6.</b> Timing
				</span>
				<p>Start Time:</p>
				<input
					type="datetime-local"
					value={jsonObj.starttime}
					onChange={changeStart}
				/>
				<p>End Time:</p>
				<input
					type="datetime-local"
					onChange={changeEnd}
					value={jsonObj.endtime}
				/>
			</div>
			<StepsContainer
				firstStep={firstStep}
				secondStep={secondStep}
				thirdStep={thirdStep}
				stepsContainerState={stepsContainerState}
			></StepsContainer>
			<div>
				<button
					aria-label={eligible ? "Do it!" : errorMessage}
					data-cooltipz-dir="top"
					data-cooltipz-size="fit"
					disabled={!eligible || !isConnnected}
					onClick={createLaunchpad}
					className="default-btn"
				>
					Confirm
				</button>
			</div>
		</div>
	);
}
