import React, { useContext, useEffect, useState } from "react";
import { WalletProvider } from "../context/WalletProvider";
import PresaleCell from "../components/PresaleCell";
const userlaunchpadABI = [
	{
		inputs: [
			{ internalType: "address", name: "_adminAddress", type: "address" },
			{ internalType: "address", name: "_token", type: "address" },
			{ internalType: "uint256", name: "_minSaleTotal", type: "uint256" },
			{ internalType: "uint256", name: "_minSaleEnter", type: "uint256" },
			{ internalType: "uint256", name: "_maxSaleEnter", type: "uint256" },
			{ internalType: "uint256", name: "_saleLength", type: "uint256" },
			{ internalType: "uint256", name: "_ethPerToken", type: "uint256" },
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "Paused",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "Unpaused",
		type: "event",
	},
	{ stateMutability: "payable", type: "fallback" },
	{
		inputs: [],
		name: "_baseTokenURI",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "close",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
		name: "deposit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "depositTotal",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "", type: "address" }],
		name: "deposits",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "ethPerToken",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getClaimableAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_eth", type: "uint256" }],
		name: "getTokenAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getUserCanClaim",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getUserCanWithdraw",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "_user", type: "address" }],
		name: "getUserClaimableAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "isClosed",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "maxSaleEnter",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "maxSaleTotal",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "minSaleEnter",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "minSaleTotal",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "ownerAllowedToWithdraw",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "participate",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [],
		name: "pause",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "paused",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "poolCreationDate",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "reservedTokenAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "saleLength",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "saleTokenAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "string", name: "baseURI", type: "string" }],
		name: "setBaseURI",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "token",
		outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "unpause",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "userAllowedToWithdraw",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "userClaim",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "userWithdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{ stateMutability: "payable", type: "receive" },
];
export default function Presales() {
	const { Web3, launchPadAbi, contractAddress } = useContext(WalletProvider);
	const [addresses, setAddresses] = useState([]);
	const [ipfs, setIpfs] = useState([]);
	const [cells, setCells] = useState([]);

	const [showPage, setShowPage] = useState(false);
	useEffect(() => {
		const getLaunchPads = async () => {
			const web3 = new Web3(window.ethereum);
			const contract = new web3.eth.Contract(launchPadAbi, contractAddress);
			let threads = [];
			let launchPadAmount = parseInt(
				await contract.methods.getLaunchpadAmount().call()
			);
			console.log(launchPadAmount);
			for (let index = 0; index < launchPadAmount; index++) {
				threads.push(contract.methods.listOfLaunchpads(index).call());
			}
			console.log(threads);
			let res = await Promise.all(threads);
			console.log(res);

			setAddresses(res);
		};

		getLaunchPads();
	}, []);
	useEffect(() => {
		if (addresses.length > 0) {
			const getData = async () => {
				const web3 = new Web3(window.ethereum);
				let result = [];
				for (let index = 0; index < addresses.length; index++) {
					const userLauncpadContract = new web3.eth.Contract(
						userlaunchpadABI,
						addresses[index]
					);
					const ipfsHash = await userLauncpadContract.methods
						._baseTokenURI()
						.call();

					result.push(ipfsHash);
					console.log(result);
				}

				setIpfs(result);
			};
			getData();
		}
	}, [addresses]);
	useEffect(() => {
		setTimeout(() => setShowPage(true));
	}, []);
	useEffect(() => {
		if (addresses.length > 0 && ipfs.length > 0) {
			let res = [];
			for (let index = 0; index < addresses.length; index++) {
				res.push(
					<PresaleCell
						launchpadAddress={addresses[index]}
						ipfsHash={ipfs[index]}
					></PresaleCell>
				);
			}
			setCells(res);
		}
	}, [addresses, ipfs]);
	return (
		<div className={`presales_page page ${showPage ? "SHOW" : ""}`}>
			<div className="presales_page-container_header">
				<span>PRESALE DIRECTORY</span>
			</div>
			<div className="presales_page-container_grid">{cells}</div>
		</div>
	);
}
