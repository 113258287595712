import React, { useContext, useEffect, useState } from "react";
import { WalletProvider } from "../context/WalletProvider";
const lockerContractAddress = "0xcB4950741Ea9d228410a74F008bF6b35AEa85b2D";

export default function LockerCell(props) {
	const { ethers, isConnnected, Web3, wallet, errorMsg, succesMsg } =
		useContext(WalletProvider);
	const [tokenName, setTokenName] = useState("");
	const [tokenSymbol, setTokenSymbol] = useState("");
	const [visible, setVisible] = useState(true);
	const lockerABI = [
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: "uint256",
					name: "vestingId",
					type: "uint256",
				},
				{
					indexed: false,
					internalType: "address",
					name: "token",
					type: "address",
				},
				{
					indexed: true,
					internalType: "address",
					name: "beneficiary",
					type: "address",
				},
				{
					indexed: false,
					internalType: "uint256",
					name: "amount",
					type: "uint256",
				},
			],
			name: "TokenVestingAdded",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: "uint256",
					name: "vestingId",
					type: "uint256",
				},
				{
					indexed: false,
					internalType: "address",
					name: "token",
					type: "address",
				},
				{
					indexed: true,
					internalType: "address",
					name: "beneficiary",
					type: "address",
				},
				{
					indexed: false,
					internalType: "uint256",
					name: "amount",
					type: "uint256",
				},
			],
			name: "TokenVestingReleased",
			type: "event",
		},
		{
			inputs: [
				{ internalType: "contract IERC20", name: "_token", type: "address" },
				{ internalType: "address", name: "_beneficiary", type: "address" },
				{ internalType: "uint256", name: "_releaseTime", type: "uint256" },
				{ internalType: "uint256", name: "_amount", type: "uint256" },
			],
			name: "addVesting",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "_vestingId", type: "uint256" },
			],
			name: "beneficiary",
			outputs: [{ internalType: "address", name: "", type: "address" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_vestingIds", type: "uint256[]" },
			],
			name: "getVestingList",
			outputs: [
				{
					components: [
						{ internalType: "uint256", name: "vestingId", type: "uint256" },
						{ internalType: "contract IERC20", name: "token", type: "address" },
						{ internalType: "uint256", name: "releaseTime", type: "uint256" },
						{ internalType: "uint256", name: "amount", type: "uint256" },
						{ internalType: "address", name: "beneficiary", type: "address" },
						{ internalType: "bool", name: "released", type: "bool" },
					],
					internalType: "struct Vesting.VestingDataFE[]",
					name: "",
					type: "tuple[]",
				},
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			name: "ownerVestings",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "_vestingId", type: "uint256" },
			],
			name: "release",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "_vestingId", type: "uint256" },
			],
			name: "releaseTime",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "_vestingId", type: "uint256" },
			],
			name: "vestingAmount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			name: "vestings",
			outputs: [
				{ internalType: "contract IERC20", name: "token", type: "address" },
				{ internalType: "uint256", name: "releaseTime", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "address", name: "beneficiary", type: "address" },
				{ internalType: "bool", name: "released", type: "bool" },
			],
			stateMutability: "view",
			type: "function",
		},
	];
	async function release() {
		const web3 = new Web3(window.ethereum);
		const lockerContract = new web3.eth.Contract(
			lockerABI,
			lockerContractAddress
		);
		lockerContract.methods
			.release(parseInt(props.id))
			.send({ from: wallet, gasPrice: "1000000000" })
			.then((res) => succesMsg(res.transactionHash))
			.catch((error) => errorMsg(error.message));
	}
	useEffect(() => {
		if (props.ben === wallet) {
			setVisible(true);
		}
	}, [props.ben]);
	useEffect(() => {
		const get = async () => {
			const web3 = new Web3(window.ethereum);
			const lockerContract = new web3.eth.Contract(props.erc20ABI, props.addr);
			setTokenName(await lockerContract.methods.name().call());
			setTokenSymbol(await lockerContract.methods.symbol().call());
			console.log(props.id);
			console.log(wallet);
		};
		get();
	}, []);
	return (
		<div className="lockerdashboard_page-info_wrapper">
			<div className="lockerdashboard_page-info_container">
				<div className="header">
					{tokenName} {tokenSymbol}
				</div>

				<div className="info_row">
					<span>Address:</span>
					<p id="adr">{props.addr}</p>
				</div>
				<div className="info_row">
					<span>Beneficiary:</span>
					<p id="adr">{props.ben}</p>
				</div>
				<div className="info_row">
					<span>Amount:</span>
					<p id="adr">{props.amount.toString()}</p>
				</div>
				<div
					style={{
						display: `${visible ? "flex" : "none"}`,
						border: "none",
					}}
					className="info_row"
					onClick={release}
				>
					<button>Release</button>
				</div>
			</div>
		</div>
	);
}
